import { CloseCircleOutlined, DeleteOutlined, StopOutlined } from '@ant-design/icons';
import { Alert, Button, Collapse, Form, message, Modal, Popconfirm, Skeleton, Space } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import CurrentUser from '../../models/CurrentUser';
import { Classification, Question, QuestionDetail } from '../../models/Question';
import { QuestionEvent } from '../../models/QuestionEvent';
import { UpdateQuestionRequestAnswer } from '../../models/requests/UpdateQuestionRequest';
import UserAssignableToQuestion from '../../models/UserAssignableToQuestion';
import OrganizationService from '../../services/OrganizationService';
import QuestionService from '../../services/QuestionService';
import UserService from '../../services/UserService';
import AuthorizationHelper from '../../utils/AuthorizationHelper';
import OrganizationUtils from '../../utils/OrganizationUtils';
import QuestionStatus from '../../utils/QuestionStatus';
import AskForInfoModal from '../AskForInfoModal';
import AssignQuestionToExpertModal from '../AssignQuestionToExpertModal';
import IntegrateAnswerModal from '../IntegrateAnswerModal';
import MenuAction from '../QuestionMenuActions/MenuActions';
import QuestionActions from '../QuestionMenuActions/QuestionActions';
import AnswerFormItem from './components/AnswerFormItem';
import ChatsFormItem from './components/ChatsFormItem';
import CommentsFormItem from './components/CommentsFormItem/CommentsFormItem';
import { DefinitiveRefuseModal } from './components/DefinitiveRefuseModal/DefinitiveRefuseModal';
import EditorFieldsFormItem from './components/EditorFieldsFormItem';
import EndUserFormItem from './components/EndUserFormItem';
import MetadataFormItem from './components/MetadataFormItem';
import PublishingFormItem from './components/PublishingFormItem';
import QuestionEventsItem from './components/QuestionEventsItem';
import QuestionFormItem from './components/QuestionFormItem';
import ModalGenAI from './components/ModalGenAI';
import Constants from '../../Constants';
import { GeneratedAnswer } from '../../models/GeneratedAnswer';

const { Panel } = Collapse;

interface QuestionModalProps {
  visible: boolean;
  currentUser: CurrentUser;
  onOk: () => void;
  onCancel: () => void;
  questionId: string;
  userService: UserService;
  questionService: QuestionService;
  organizationService: OrganizationService;
  showPublishQuestionModal?: (question?: Question | undefined) => void | undefined;
  showAssignQuestionToContainerModal?: (question?: Question | undefined) => void | undefined;
}

const QuestionModal: React.FC<QuestionModalProps> = (props) => {
  const [questionForm] = Form.useForm();
  const [question, setQuestion] = useState<QuestionDetail | undefined>(undefined);
  const [questionEvents, setQuestionEvents] = useState<QuestionEvent[]>([]);

  // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isWorking, setIsWorking] = useState<boolean>(false);

  const [isAssignToUserModalOpen, setIsAssignToUserModalOpen] = useState<boolean>(false);
  const [isDefinitiveRefuseModalOpen, setIsDefinitiveRefuseModalOpen] = useState<boolean>(false);
  const [isAskForInfoModalOpen, setIsAskForInfoModalOpen] = useState<boolean>(false);
  const [isIntegrateAnswerModalOpen, setIsIntegrateAnswerModalOpen] = useState<boolean>(false);

  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [assignedTo, setAssignedTo] = useState<UserAssignableToQuestion | undefined>(undefined);

  const [selectedAnswerIndex, setSelectedAnswerIndex] = useState<number>(-1);

  const [formFieldsChanged, setFormFieldsChanged] = useState<boolean>(false);
  const [popConfirmExitWithChangesVisible, setPopConfirmExitWithChangesVisible] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [generatedAnswerCallback, setGeneratedAnswerCallback] = useState<string>('');
  const [generatedAnswerIdCallback, setGeneratedAnswerIdCallback] = useState<string>('');
  const [checkHasGenAIAnswerId, setCheckHasGenAIAnswerId] = useState<boolean>(false);
  const [checkHasFeedbackAdded, setCheckHasFeedbackAdded] = useState<boolean>(false);
  const [isModalGenAIFromPropose, setIsModalGenAIFromPropose] = useState<boolean>(false);
  const [genAIModalAction, setGenAIModalAction] = useState<(() => Promise<void>) | null>(() => null);
  const [successMessage, setSuccessMessage] = useState<string | undefined>(undefined);

  const updateStateVariables = (retrievedQuestion: QuestionDetail) => {
    if (retrievedQuestion) {
      setCheckHasGenAIAnswerId(!!retrievedQuestion.answerGenAiId);
      setCheckHasFeedbackAdded(!!retrievedQuestion.feedbackAdded);
    }
  };

  const getQuestionDetails = useCallback(async () => {
    const retrievedQuestion = await props.questionService.getQuestionDetails(props.questionId);

    if (retrievedQuestion) {
      await OrganizationUtils.addOrganizationNamesToQuestions([retrievedQuestion], props.organizationService);

      const questionEvents = await props.questionService.getStatusHistory(props.questionId);

      setQuestionEvents(questionEvents);
      setQuestion(retrievedQuestion);
    }
    updateStateVariables(retrievedQuestion);
  }, [props.organizationService, props.questionId, props.questionService]);

  useEffect(() => {
    if (!props.questionId) {
      return;
    }

    getQuestionDetails();
  }, [props.questionId, getQuestionDetails]);

  const validateForm = async (): Promise<boolean> => {
    try {
      await questionForm.validateFields();
      return true;
    } catch (e: any) {
      return false;
    }
  };

  const assignedToIsChanged = (): boolean => {
    if (assignedTo === undefined) return false;
    return question?.assignedTo?.id !== assignedTo?.id;
  };

  const onCancel = () => {
    setErrorMessage(undefined);
    props.onCancel();
  };

  const canEdit = (): boolean => {
    return question !== undefined && AuthorizationHelper.canEditQuestion(props.currentUser, question);
  };

  const canEditBeforeEditorApproval = (): boolean => {
    return canEdit() && QuestionStatus.beforeACE(question!.status);
  };

  const canEditAnswer = (): boolean => {
    return question !== undefined && AuthorizationHelper.canEditAnswer(props.currentUser, question);
  };

  const canEditRubricAndSubRubric = (): boolean => {
    return question !== undefined && AuthorizationHelper.canEditQuestionRubricAndSubRubric(props.currentUser, question);
  };

  const canEditClassifications = (): boolean => {
    return question !== undefined && AuthorizationHelper.canEditClassifications(props.currentUser, question);
  };

  const answerFormItemIsVisible = (): boolean => {
    return question !== undefined && !QuestionStatus.beforePROG(question.status);
  };

  const editorialFieldsAreVisible = (): boolean => {
    return question !== undefined && props.currentUser.isAtLeastEditor();
  };

  const chatPanelIsVisibile = (): boolean => {
    return (
      question !== undefined && (question.status !== 'ING' || (question.status === 'ING' && !!question.chats?.length))
    );
  };

  const saveButtonIsVisible = (): boolean => {
    return (
      question !== undefined &&
      (AuthorizationHelper.canEditQuestion(props.currentUser, question) ||
        AuthorizationHelper.questionCanBeAssignedByUser(props.currentUser, question) ||
        canEditAnswer())
    );
  };

  const pubblicationPanelIsVisible = (): boolean => {
    return (
      question !== undefined &&
      question.status === QuestionStatus.PUBLISHED &&
      AuthorizationHelper.isAtLeastEditorOrSuperEditorOn(props.currentUser, question)
    );
  };

  class QuestionModalMenuActions implements MenuAction {
    PUBLISH = async () => {
      console.log('PUBLISH');
      props.showPublishQuestionModal?.(question);
    };
    ASSIGN_TO_CONTAINER = async () => {
      console.log('ASSIGN_TO_CONTAINER');
      props.showAssignQuestionToContainerModal?.(question);
    };
    SAVE = async () => {
      console.log('SAVE');
      await updateQuestion();
      await getQuestionDetails();
    };
    DISCARD = async () => {
      console.log('DISCARD');
      await props.questionService.discardQuestion([question!.id]);
      await getQuestionDetails();
    };
    RETURN_TO_ASSIGN_STATUS = async () => {
      console.log('RETURN_TO_ASSIGN_STATUS');
      await props.questionService.returnToAssignStatus(question!.id);
      await getQuestionDetails();
    };
    REJECT_BY_EXPERT = async () => {
      console.log('REJECT_BY_EXPERT');
      await updateQuestion();
      await props.questionService.acceptOrRejectByExpertQuestion([question!.id], false);
      await getQuestionDetails();
    };
    ACCEPT_BY_EXPERT = async () => {
      console.log('ACCEPT_BY_EXPERT');
      await updateQuestion();
      await props.questionService.acceptOrRejectByExpertQuestion([question!.id], true);
      await getQuestionDetails();
    };
    PUT_ANSWER_IN_READY = async () => {
      console.log('PUT_ANSWER_IN_READY');
      await updateQuestion();
      await props.questionService.readyQuestion([question!.id]);
      await getQuestionDetails();
    };
    PROPOSE_ANSWER = async () => {
      console.log('PROPOSE_ANSWER');
      await updateQuestion();
      await props.questionService.proposeAnswer(question!.id);
      await getQuestionDetails();
    };
    REFUSE_ANSWER = async () => {
      console.log('REFUSE_ANSWER');
      await updateQuestion();
      await props.questionService.refuseAnswer([question!.id]);
      await getQuestionDetails();
    };
    ACCEPT_ANSWER = async () => {
      console.log('ACCEPT_ANSWER');
      await updateQuestion();
      await props.questionService.acceptAnswer([question!.id]);
      await getQuestionDetails();
    };
    RECLASSIFY_QUESTION = async () => {
      console.log('RECLASSIFY_QUESTION');
      let classifications = await props.questionService.reClassify(question!.id);
      question!.classifications = classifications;
      questionForm.setFieldsValue({
        classifications: classifications.map(mapClassificationToForm()),
      });
    };
    QUESTION_ASK_VALIDATION = async () => {
      console.log('QUESTION_ASK_VALIDATION');
      await props.questionService.askValidation([question!.id]);
      await getQuestionDetails();
    };
    VALIDATE_QUESTION = async () => {
      console.log('VALIDATE_QUESTION');
      await props.questionService.validateQuestion(question!.id);
      await getQuestionDetails();
    };
    UNLOCK_NOTIFIED_QUESTION = async () => {
      console.log('UNLOCK_NOTIFIED_QUESTION');
      await props.questionService.unlockNotifiedQuestion([question!.id]);
      await getQuestionDetails();
    };
    NOTIFY_TO_USER = async () => {
      console.log('NOTIFY_TO_USER');
      await props.questionService.notifyQuestion([question!.id]);
      await getQuestionDetails();
    };
    SAVE_AND_REPUBLISH = async () => {
      console.log('SAVE_AND_REPUBLISH');
      await updateQuestion();
      await props.questionService.republishQuestion(question!.id);
      await getQuestionDetails();
    };
    ASSIGN_TO_EXPERT = async () => {
      console.log('ASSIGN_TO_EXPERT');
      setIsAssignToUserModalOpen(true);
    };
    MULTIPLE_ASSIGN_TO_EXPERT = async () => {
      console.log('MULTIPLE_ASSIGN_TO_EXPERT');
      setIsAssignToUserModalOpen(true);
    };
    ASSIGN_TO_ME = async () => {
      console.log('ASSIGN_TO_ME');
      await props.questionService.assignQuestionTo(question!.id, props.currentUser.id);
      await getQuestionDetails();
    };
    INTEGRATE_ANSWER = async () => {
      console.log('INTEGRATE_ANSWER');
      setIsIntegrateAnswerModalOpen(true);
    };
  }

  const menuActions = new QuestionModalMenuActions();

  const handleSuccess = async (
    action: (() => Promise<void>) | null,
    successMessage?: string,
    errorMessage?: string,
  ) => {
    if (action) {
      try {
        await action();
        successMessage && message.success(successMessage);
        setErrorMessage(undefined);
      } catch (error) {
        errorMessage && message.error(errorMessage);
        setErrorMessage(errorMessage);
        setSuccessMessage(undefined);
      }
      setIsWorking(false);
    } else {
      console.error('Action è null');
    }
  };

  const actionOnConfirm = async (
    action: () => Promise<void>,
    successMessage?: string,
    errorMessage?: string,
    fromFeedbackModal?: boolean,
  ) => {
    if (await validateForm()) {
      try {
        if (!checkHasGenAIAnswerId && generatedAnswerCallback !== '' && !fromFeedbackModal) {
          setIsModalGenAIFromPropose(true);
          setGenAIModalAction(() => action);
          setSuccessMessage(successMessage);
          handleOpenModal();
        } else {
          setIsModalGenAIFromPropose(false);
          setIsWorking(true);
          handleSuccess(action, successMessage);
        }
      } catch (e: any) {
        errorMessage && message.error(errorMessage);
        setIsModalGenAIFromPropose(false);
        setIsWorking(false);
        setErrorMessage(e.toString());
      }
    }
  };

  const updateQuestion = async (): Promise<void> => {
    if (canEdit() || canEditAnswer()) {
      const values = questionForm.getFieldsValue(true);

      let editorSubject = values.editorSubjectLine1;

      if (values.editorSubjectLine2 !== '') {
        editorSubject += Constants.STRING_SPLITTER + values.editorSubjectLine2;
      }

      try {
        await props.questionService.updateQuestion({
          id: question!.id,
          channel: question!.channelId,
          rubric: canEditRubricAndSubRubric() ? values.rubric : question!.rubricId,
          subRubric: canEditRubricAndSubRubric() ? values.subRubric : question!.subRubricId,
          subject: values.subject,
          question: values.question,
          answers: values.answers,
          editorSubject: editorSubject,
          editorQuestion: values.editorQuestion,
          endUserCity: values.endUserCity,
          classifications: values.classifications,
        });

        if (assignedToIsChanged()) {
          await props.questionService.assignQuestionTo(question!.id, assignedTo!.id);
        }
      } catch (e) {
        console.error(e);
      }
    }
  };

  const getGenAIFromLambda = async (questionQ: string, questionId: string): Promise<GeneratedAnswer> => {
    try {
      const resp = await props.questionService.getGenAIfromLambda(questionQ, questionId);
      return resp;
    } catch (e) {
      console.error(e);
      return { answer: 'ERROR!!', answerGenAiId: '', error: 'ERROR!!' };
    }
  };

  const addFeebackGenAI = async (
    answerGenAiId: string,
    feedbackExpert: string,
    numericFeedback: number,
  ): Promise<void> => {
    try {
      await props.questionService.addFeedback(answerGenAiId, feedbackExpert, numericFeedback);
    } catch (e) {
      console.error(e);
    }
  };

  const handleGeneratedAnswer = (generatedAnswer: string) => {
    setGeneratedAnswerCallback(generatedAnswer);
  };

  const handleGeneratedAnswerId = (generatedAnswerId: string) => {
    setGeneratedAnswerIdCallback(generatedAnswerId);
  };

  const updateQuestionWithEmptyNewAnswer = async (): Promise<void> => {
    if (AuthorizationHelper.canAddNewAnswers(props.currentUser, question!)) {
      const values = questionForm.getFieldsValue(true);

      let editorSubject = values.editorSubjectLine1;

      if (values.editorSubjectLine2 !== '') {
        editorSubject += Constants.STRING_SPLITTER + values.editorSubjectLine2;
      }

      const emptyAnswer: UpdateQuestionRequestAnswer = {
        answer: '',
      };

      try {
        await props.questionService.updateQuestion({
          id: question!.id,
          channel: question!.channelId,
          rubric: canEditRubricAndSubRubric() ? values.rubric : question!.rubricId,
          subRubric: canEditRubricAndSubRubric() ? values.subRubric : question!.subRubricId,
          subject: values.subject,
          question: values.question,
          answers: values.answers.concat(emptyAnswer),
          editorSubject: editorSubject,
          editorQuestion: values.editorQuestion,
          endUserCity: values.endUserCity,
          classifications: values.classifications,
        });

        if (assignedToIsChanged()) {
          await props.questionService.assignQuestionTo(question!.id, assignedTo!.id);
        }
      } catch (e) {
        console.error(e);
      }
    }
  };

  const selectAnswer = (answerId: string) => {
    const answers = question?.answers;
    if (answers) {
      const answerIndex = answers.findIndex((a) => a.id === answerId);
      setSelectedAnswerIndex(answerIndex);
    }
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalGenAIFromPropose(false);
    setIsModalOpen(false);
  };

  const handleSubmit = (feedback: string, rating: number, isFromPropose: boolean = false) => {
    const answerGenAiId = question ? (question.answerGenAiId ? question.answerGenAiId : generatedAnswerIdCallback) : '';
    addFeebackGenAI(answerGenAiId, feedback, rating);
    if (popConfirmExitWithChangesVisible) {
      onFinish()
        .then(() => {
          setIsWorking(false);
          onCancel();
        })
        .catch(console.error);
    } else if (isFromPropose) {
      handleSuccess(genAIModalAction, 'Risposta proposta correttamente', 'Errore nel proporre la risposta');
      /* onFinish(true); */
    } else {
      onFinish(true);
    }
    handleCloseModal();
  };

  const handleClick = () => {
    if (!checkHasGenAIAnswerId) {
      if (generatedAnswerCallback !== '') {
        handleOpenModal();
        console.log('handleOpenModal - genAiAnswerId non presente ma risposta genai appena richiesta');
      } else {
        console.log('onFinish - risposta non genai');
        if (popConfirmExitWithChangesVisible) {
          onFinish()
            .then(() => {
              setIsWorking(false);
              onCancel();
            })
            .catch(console.error);
        } else {
          onFinish();
        }
      }
    } else {
      if (checkHasFeedbackAdded) {
        console.log('onFinish - feedback era true');
        if (popConfirmExitWithChangesVisible) {
          onFinish()
            .then(() => {
              setIsWorking(false);
              onCancel();
            })
            .catch(console.error);
        } else {
          onFinish();
        }
      } else {
        console.log('handleOpenModal - feedback era false');
        handleOpenModal();
      }
    }
  };

  const FooterButtons = () => {
    return (
      question && (
        <Space>
          <QuestionActions
            questions={[question]}
            currentUser={props.currentUser}
            assignedTo={assignedTo}
            isWorking={isWorking}
            setIsWorking={setIsWorking}
            setIsDefinitiveRefuseModalOpen={setIsDefinitiveRefuseModalOpen}
            setIsAskForInfoModalOpen={setIsAskForInfoModalOpen}
            actionOnConfirm={actionOnConfirm}
            menuActions={menuActions}
            label={'Azioni'}
          />
          <Button
            key="submit"
            htmlType="submit"
            type="primary"
            hidden={!saveButtonIsVisible()}
            disabled={!formFieldsChanged}
            loading={isWorking}
            onClick={handleClick}
            data-testid="question-modal-save-button"
          >
            Salva
          </Button>
          <ModalGenAI
            visible={isModalOpen}
            onCancel={handleCloseModal}
            onSubmit={handleSubmit}
            isModalGenAIFromPropose={isModalGenAIFromPropose}
          />
        </Space>
      )
    );
  };

  const loadQuestionData = useCallback(async () => {
    if (!question) {
      return;
    }

    let editorSubjectLine1 = '';
    let editorSubjectLine2 = '';

    if (question && question.editorSubject) {
      const editorSubject = question.editorSubject.split(Constants.STRING_SPLITTER);
      editorSubjectLine1 = editorSubject[0];

      if (editorSubject.length > 1) {
        editorSubjectLine2 = editorSubject[1];
      }
    }

    questionForm.setFieldsValue({
      subject: question.subject,
      question: question.question,
      answers: question.answers,
      editorSubjectLine1: editorSubjectLine1,
      editorSubjectLine2: editorSubjectLine2,
      editorQuestion: question.editorQuestion,
      classifications: question.classifications?.sort(sortClassifications()).map(mapClassificationToForm()),
      endUserCity: question.endUserCity,
      editorAnswer: question.answers?.map((a) => a.editorAnswer),
    });
    setAssignedTo(question.assignedTo as UserAssignableToQuestion);
    setFormFieldsChanged(false);
  }, [question, questionForm]);

  useEffect(() => {
    loadQuestionData();
  }, [loadQuestionData]);

  const UnpublishButton: React.FC<any> = () => {
    const [loading, setLoading] = useState(false);

    const onClickFunction = async () => {
      try {
        setLoading(true);
        await props.questionService.unpublishQuestion(question!.id);
        await getQuestionDetails();
        message.success(`Quesito è stato rimosso con successo da tutti i canali`);
      } catch (e) {
        message.error(JSON.stringify(e));
      } finally {
        setLoading(false);
      }
    };

    return (
      <Button loading={loading} type="primary" icon={<StopOutlined />} onClick={onClickFunction}>
        Depubblica
      </Button>
    );
  };

  const RemoveOutputChannelButton: React.FC<{ outputChannelId: string }> = ({ outputChannelId }) => {
    const [loading, setLoading] = useState(false);

    const onClickFunction = async () => {
      try {
        setLoading(true);
        await props.questionService.removeQuestionFromOutputChannel(question!.id, outputChannelId);
        await getQuestionDetails();
        message.success(`Quesito rimosso con successo dal canale selezionato`);
      } catch (e) {
        message.error(JSON.stringify(e));
      } finally {
        setLoading(false);
      }
    };

    return (
      <Button loading={loading} type="primary" icon={<DeleteOutlined />} onClick={onClickFunction}>
        Rimuovi
      </Button>
    );
  };

  return (
    <Modal
      width={800}
      title={`Dettaglio del quesito ${question !== undefined ? question.id : ''}`}
      data-testid="question-modal"
      visible={props.visible}
      onCancel={onClose}
      maskClosable={false}
      centered
      destroyOnClose={false}
      getContainer={false}
      forceRender
      closeIcon={
        <Popconfirm
          placement="bottom"
          title={'Ci sono delle modifiche non salvate. Sei sicuro di voler uscire senza salvare?'}
          visible={popConfirmExitWithChangesVisible}
          onConfirm={handlePopConfirmExitWithChangesOk}
          onCancel={handlePopConfirmExitWithChangesCancel}
          okButtonProps={{ loading: isWorking }}
          icon={
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setTimeout(() => setPopConfirmExitWithChangesVisible(false), 0); // TODO: Double check this one here!
              }}
            >
              <CloseCircleOutlined data-testid="question-modal-close-without-saving-button" />
            </div>
          }
          okText="Salva ed esci"
          cancelText="Esci senza salvare"
        >
          <CloseCircleOutlined key="back" onClick={onClose} data-testid="question-modal-close-button" />
        </Popconfirm>
      }
      footer={FooterButtons()}
      className="question-modal"
    >
      {!isLoading && question ? (
        <>
          {question.status === QuestionStatus.REJ && (
            <Alert
              message="Quesito Rifiutato Definitivamente"
              description={`Motivo del Rifiuto: ${question.definitiveRefuseReason ?? 'nessuna motivazione fornita'}`}
              type="error"
              showIcon
            />
          )}
          <Form
            form={questionForm}
            name="question-modal-form"
            layout="vertical"
            onFinish={onFinish}
            onValuesChange={() => setFormFieldsChanged(true)}
          >
            <Collapse defaultActiveKey={defaultOpenPanelKeys()}>
              <Panel header="Domanda" key="question-panel">
                <QuestionFormItem
                  question={question}
                  questionForm={questionForm}
                  currentUser={props.currentUser}
                  organizationService={props.organizationService}
                  userService={props.userService}
                  assignedTo={assignedTo}
                  setAssignedTo={setAssignedTo}
                  canEdit={canEditBeforeEditorApproval}
                  canEditClassifications={canEditClassifications}
                  canEditRubricAndSubRubric={canEditRubricAndSubRubric}
                />
              </Panel>

              <Panel key="question-metadata" header="Metadati" forceRender>
                <MetadataFormItem
                  question={question}
                  questionForm={questionForm}
                  currentUser={props.currentUser}
                  organizationService={props.organizationService}
                  userService={props.userService}
                  assignedTo={assignedTo}
                  setAssignedTo={setAssignedTo}
                  canEditClassifications={canEditClassifications}
                  canEditRubricAndSubRubric={canEditRubricAndSubRubric}
                  setFormFieldsChanged={setFormFieldsChanged}
                />
              </Panel>

              <Panel key="question-user-data" header="Dettagli dell'utente" forceRender>
                <EndUserFormItem question={question} currentUser={props.currentUser} />
              </Panel>

              {answerFormItemIsVisible() && (
                <Panel header="Risposta" key="question-answer">
                  <div data-testid="question-modal-form-answer-panel">
                    <AnswerFormItem
                      answers={question.answers}
                      currentUser={props.currentUser}
                      question={question}
                      selectAnswer={selectAnswer}
                      genAIAnswer={getGenAIFromLambda}
                      updateQuestionWithEmptyNewAnswer={async () => {
                        await updateQuestionWithEmptyNewAnswer();
                        await getQuestionDetails();
                      }}
                      form={questionForm}
                      setFormFieldsChanged={setFormFieldsChanged}
                      onGeneratedAnswer={handleGeneratedAnswer}
                      onGeneratedAnswerId={handleGeneratedAnswerId}
                    />
                  </div>
                </Panel>
              )}

              {editorialFieldsAreVisible() && (
                <Panel
                  header="Campi editoriali"
                  key="editor-fields"
                  data-testid="question-modal-form-editor-fields"
                  forceRender
                >
                  <EditorFieldsFormItem
                    readonly={!canEdit()}
                    question={question}
                    form={questionForm}
                    selectedAnswerIndex={selectedAnswerIndex}
                  />
                </Panel>
              )}

              {chatPanelIsVisibile() && (
                <Panel key="question-chat-data" header="Richiesta chiarimenti" forceRender>
                  <ChatsFormItem question={question} />
                </Panel>
              )}
              {pubblicationPanelIsVisible() && (
                <Panel
                  key="publishing-output-channel-data"
                  header="Pubblicazione"
                  data-testid="question-modal-publication-detail"
                  forceRender
                >
                  <PublishingFormItem
                    question={question}
                    UnpublishButton={UnpublishButton}
                    RemoveOutputChannelButton={RemoveOutputChannelButton}
                  />
                </Panel>
              )}

              <Panel header="Commenti" key="question-comments" forceRender>
                <div data-testid="question-modal-form-comments-panel">
                  <CommentsFormItem
                    questionService={props.questionService}
                    question={question}
                    comments={question.comments}
                    currentUser={props.currentUser}
                  />
                </div>
              </Panel>

              <Panel header="Storico stati" key="question-events" data-testid="question-modal-form-events-panel">
                <QuestionEventsItem questionEvents={questionEvents} />
              </Panel>
            </Collapse>
          </Form>

          <IntegrateAnswerModal
            isModalVisible={isIntegrateAnswerModalOpen}
            question={question}
            onOk={async () => {
              setIsIntegrateAnswerModalOpen(false);
              await getQuestionDetails();
            }}
            onCancel={() => setIsIntegrateAnswerModalOpen(false)}
            userService={props.userService}
            questionService={props.questionService}
          />
          <AssignQuestionToExpertModal
            isModalVisible={isAssignToUserModalOpen}
            question={question}
            onOk={async () => {
              setIsAssignToUserModalOpen(false);
              await getQuestionDetails();
            }}
            onCancel={() => setIsAssignToUserModalOpen(false)}
            userService={props.userService}
            questionService={props.questionService}
          />
          <DefinitiveRefuseModal
            onCancel={() => setIsDefinitiveRefuseModalOpen(false)}
            onOk={async () => {
              setIsDefinitiveRefuseModalOpen(false);
              onCancel();
            }}
            questionService={props.questionService}
            question={question}
            visible={isDefinitiveRefuseModalOpen}
          />
          <AskForInfoModal
            visible={isAskForInfoModalOpen}
            setVisible={setIsAskForInfoModalOpen}
            questionId={question.id}
            questionsService={props.questionService}
            onOk={async () => {
              onCancel();
            }}
          />
        </>
      ) : (
        <Skeleton />
      )}
      {errorMessage !== undefined && (
        <div style={{ marginTop: 16 }}>
          <Alert message={errorMessage} type="error" showIcon />
        </div>
      )}
    </Modal>
  );

  function defaultOpenPanelKeys() {
    const defaultKeys = ['question-panel', 'question-answer'];
    let keysToReturn = [...defaultKeys];

    if (question?.comments?.length) {
      keysToReturn.push('question-comments');
    }

    if (question?.chats?.length) {
      keysToReturn.push('question-chat-data');
    }

    return keysToReturn;
  }

  function onClose() {
    if (formFieldsChanged) {
      setPopConfirmExitWithChangesVisible(true);
    } else {
      onCancel();
    }
  }

  function handlePopConfirmExitWithChangesOk() {
    setPopConfirmExitWithChangesVisible(false);
    setIsWorking(true);
    handleClick();
  }

  function handlePopConfirmExitWithChangesCancel() {
    setPopConfirmExitWithChangesVisible(false);
    onCancel();
  }

  async function onFinish(fromFeedbackModal: boolean = false): Promise<void> {
    await actionOnConfirm(
      menuActions.SAVE,
      'Quesito salvato correttamente',
      'Errore nel salvataggio',
      fromFeedbackModal,
    );
  }
};

function sortClassifications() {
  return (a: Classification, b: Classification) => {
    // auto before manual
    if (!a.manual && b.manual) {
      return -1;
    } else if (a.manual && !b.manual) {
      return 1;
    }
    // if both auto then rank order
    if (!a.manual && !b.manual) {
      return a.rank < b.rank ? 1 : -1;
    }
    // alphabetic otherwise
    return a.classificationValue < b.classificationValue ? -1 : 1;
  };
}

function mapClassificationToForm() {
  return (classification: Classification) => ({
    ...classification,
    formRank: classification.manual ? 'MANUAL' : `AUTO ${classification.rank}%`,
  });
}

export default QuestionModal;
